.ltr {
  text-align: left;
}

.editor-bold {
    font-weight:700;
    color:indigo;
}

.contentEditable {
    outline: 0px solid transparent;
    cursor: text;
    border: 1px solid #ccc;
    border-radius:3px;
    padding: 5px 10px;
    overflow: auto;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    position:relative;
}

.contentEditable:focus{
    box-shadow: 0 0 5px rgba(81, 203, 238, 1);
    border: 1px solid rgba(81, 203, 238, 1);
    outline: 0px solid transparent;
}

.placeHolder{
    position:absolute;
    top:20px;
    left:10px;
    z-index:1;
    color:#aaa;
}