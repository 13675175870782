
  .ViewContainer {
    list-style: none;
    -ms-box-orient: horizontal;
    display: flex;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    align-items: center;
  }
  


  